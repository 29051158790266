/* General Reset */
body, h1, h2, p, ul, li {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Arial', sans-serif;
}

/* Body Styling */
body {
  line-height: 1.6;
  color: #333;
  background: #f4f4f9;
  padding: 0;
  margin: 0;
}

/* Header Styling */
header {
  background: #222;
  color: #fff;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1000;
}

header h1 {
  font-size: 1.5rem;
}

header nav a {
  color: #fff;
  text-decoration: none;
  margin: 0 1rem;
  font-weight: bold;
  transition: color 0.3s ease;
}

header nav a:hover {
  color: #0af;
}

/* Main Styling */
main {
  padding: 2rem;
}

/* Homepage Section */
#home {
  background: #fff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

#home h2 {
  font-size: 1.8rem;
  color: #222;
  margin-bottom: 1rem;
}

/* Contact Section */
#contact {
  background: #fff;
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

#contact h2 {
  font-size: 1.8rem;
  color: #222;
  margin-bottom: 1rem;
}

#contact ul {
  list-style: none;
  margin-top: 1rem;
}

#contact li {
  margin: 0.5rem 0;
  font-size: 1rem;
}

/* Footer Styling */
footer {
  background: #222;
  color: #fff;
  text-align: center;
  padding: 1rem 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

footer p {
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  header {
    flex-direction: column;
    text-align: center;
  }

  header nav {
    margin-top: 1rem;
  }

  header nav a {
    margin: 0.5rem;
  }
}

/* Enhanced Privacy Policy Section */
#privacy {
  background: #fff;
  border-radius: 8px;
  padding: 2.5rem;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  margin-top: 2rem;
  line-height: 1.8;
  color: #333;
}

#privacy h2 {
  font-size: 2rem;
  color: #222;
  margin-bottom: 1.5rem;
  border-bottom: 2px solid #ddd;
  padding-bottom: 0.5rem;
}

#privacy h3 {
  font-size: 1.6rem;
  color: #444;
  margin-bottom: 1rem;
  border-left: 4px solid #0af;
  padding-left: 1rem;
}

#privacy p {
  margin-bottom: 1.2rem;
  line-height: 1.8;
  color: #555;
  text-align: justify;
}

#privacy ul {
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-top: 1rem;
}

#privacy li {
  margin: 0.7rem 0;
  font-size: 1.1rem;
  color: #444;
}

#privacy a {
  color: #0af;
  text-decoration: none;
  font-weight: bold;
}

#privacy a:hover {
  text-decoration: underline;
}
